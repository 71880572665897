
.main-color {
  color: var(--calc-main-color);
}

.App {
  text-align: center;
  color: var(--calc-text-color-light)
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.calc-desc,
.disclaimer {
  color: var(--calc-text-color-light)
}

.small {
  font-size: .7em;
  margin-top: .5em;
}

.App-header {
  /* background-color: var(--calc-text-color-dark); */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.wrapper {
  width: 100%;
  /* height: 400px; */
  background-color: var(--calc-text-color-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
  border-radius: 20px;
  transition: all 1s;
}

.nav-arrow {
  border: 1px solid var(--calc-text-color-light);
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 1em;
}

.nav-arrow.active {
  border: none;
  font-size: 1.4em;
  background: var(--calc-sec-color);
  border-radius: 10px;
  color: var(--calc-text-color-dark);
  cursor: pointer;
  transition: all 1s;
}

.nav-arrow:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.nav-arrow.active::before {
  content: "\2713 ";

}

.question {
  margin: 2em 0;
  background: rgba(255, 255, 255, .1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: ease-in 1s;
}

.question-headline {
  color: var(--calc-main-color);
}

.answers-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2em 0 0 0;
}

.answer-btn {
  height: 50px;
  color: var(--calc-text-color-light);
  padding: 7px 12px;
  margin: .5em;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
}

.submit_btn {
  height: 50px;
  color: var(--calc-text-color-light);
  padding: 7px 12px;
  margin: .5em;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
}

.submit_btn.active {
  background: var(--calc-main-color);
  color: var(--calc-text-color-dark);
  font-weight: bold;
  transform: scale(1.1);
}

.answer-btn.active {
  background: var(--calc-main-color);
  color: var(--calc-text-color-dark);
  font-weight: bold;
  transform: scale(1.1);
}

.answer-btn.active span {
  display: none;
}

.answer-btn:hover {
  transform: scale(1.1);
  color: var(--calc-text-color-dark);
  background: var(--calc-main-color);
}

.answer-btn.active:hover {
  background: var(--calc-sec-color);
  color: var(--calc-text-color-dark);
  transform: scale(1.1);

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}